import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Paper, Button, Alert, useTheme } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { addDays } from 'date-fns';
import SetHeaderData from '../components/SetHeaderData';
import axios from 'axios';
import LoadingSpinner from "../components/LoadingSpinner";

const BookHolidaysForm = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [selectedRange, setSelectedRange] = useState([null, null]);
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [info, setInfo] = useState('');
  const [disabledDates, setDisabledDates] = useState([]);
  const [screen, setScreen] = useState('mailForm');
  const [mailResponse, setMailResponse] = useState('');
  const [customerIP, setCustomerIP] = useState(null);

  
  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/book_holidays/${language}.json`);
      setContent(response);
    };
    
    const loadDisabledDates = async () => {
      const response = await import('../data/book_holidays/holidays.json');
      console.log("response: ",response)
      const dates = response.default.map((date) => {
        const [year, month, day] = date.split('-');
        return new Date(year, month - 1, day);
      });
      setDisabledDates(dates);
    };

    const fetchCustomerIP = async () => {
      try {
        const ipResponse = await fetch('https://api64.ipify.org?format=json');
        const ipData = await ipResponse.json();
        setCustomerIP(ipData.ip);
      } catch (error) {
        console.error('Failed to fetch IP or geolocation:', error);
      }
    };

    loadContent();
    loadDisabledDates();
    fetchCustomerIP();
  }, [language]);

  const handleFormSubmit = async(e) => {
    e.preventDefault();

    const formData = { name, address, email, city, phone, selectedRange, info, customerIP}

    try {
      console.log('Form submitted:', 
        { name, address, email, city, phone, selectedRange, info, customerIP});
      await axios.post('SendHolidayRequest.php', formData);
      setMailResponse(content.successfully_sent);
    } catch (error) {
      console.log('Error sending form:', error);
      setMailResponse(content.failed_sent);
    }
    setScreen('noMailForm');
  };

  if (!content) return <LoadingSpinner />;

  const renderConfirmationScreen = () => (
    <Box sx={{ textAlign: 'center', my: 4 }}>
      <Alert severity="success">
        <Typography variant="h6" component="h3" gutterBottom>
          {content.successfully_sent}
        </Typography>
      </Alert>
    </Box>
  );

  const renderFailureScreen = () => (
    <Box sx={{ textAlign: 'center', my: 4 }}>
      <Alert severity="error">
        <Typography variant="h6" component="h3" gutterBottom>
          {content.failed_sent}
        </Typography>
      </Alert>
    </Box>
  );

  if (screen === 'noMailForm' && mailResponse === content.successfully_sent) {
    return renderConfirmationScreen();
  }

  if (screen === 'noMailForm' && mailResponse === content.failed_sent) {
    return renderFailureScreen();
  }

  return (
    <Container component="main">

      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>
      <Box
        sx={{
          textAlign: 'center',
          my: 4,
          p: 2,
          border: '1px solid',
          borderColor: theme.palette.divider,
          borderRadius: 2,
          backgroundColor: theme.palette.background.paper,
          }}>
        <Container component="main" maxWidth="sm">
          <Typography variant="body" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section1}</Typography>
          <SetHeaderData content={content} />
          <Paper elevation={3} sx={{ p: 4, mt: 4, backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>

            <form onSubmit={handleFormSubmit}>
              <Box sx={{ my: 2 }}>
                <TextField
                  label={content.form_fields.name}
                  value={name}
                  variant="outlined"
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  required
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: theme.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: theme.palette.divider,
                    },
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                      WebkitTextFillColor: theme.palette.text.primary,
                      transition: 'background-color 5000s ease-in-out 0s',
                    },
                  }}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label={content.form_fields.address}
                  variant="outlined"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: theme.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: theme.palette.divider,
                    },
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                      WebkitTextFillColor: theme.palette.text.primary,
                      transition: 'background-color 5000s ease-in-out 0s',
                    },
                  }}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label={content.form_fields.city}
                  variant="outlined"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: theme.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: theme.palette.divider,
                    },
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                      WebkitTextFillColor: theme.palette.text.primary,
                      transition: 'background-color 5000s ease-in-out 0s',
                    },
                  }}
                />
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label={content.form_fields.phone}
                  variant="outlined"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  required
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: theme.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: theme.palette.divider,
                    },
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                      WebkitTextFillColor: theme.palette.text.primary,
                      transition: 'background-color 5000s ease-in-out 0s',
                    },
                  }}
                />
              </Box>
              <Box sx={{ my: 2 }}>
                <TextField
                  label="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                  required
                  variant="outlined"
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: theme.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: theme.palette.divider,
                    },
                    '& input:-webkit-autofill': {
                      WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                      WebkitTextFillColor: theme.palette.text.primary,
                      transition: 'background-color 5000s ease-in-out 0s',
                    },
                  }}
                />
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography>{content.form_fields.date_range}</Typography>
                <DatePicker
                  selected={selectedRange[0]}
                  onChange={(dates) => setSelectedRange(dates)}
                  startDate={selectedRange[0]}
                  endDate={selectedRange[1]}
                  selectsRange
                  minDate={new Date()}
                  maxDate={addDays(new Date(), 365)}
                  excludeDates={disabledDates}
                  inline
                />
                <Typography variant="body2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section2}</Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <TextField
                  fullWidth
                  label={content.form_fields.info}
                  variant="outlined"
                  multiline
                  rows={4}
                  value={info}
                  onChange={(e) => setInfo(e.target.value)}
                  sx={{
                    '& .MuiInputLabel-root': {
                      color: theme.palette.text.secondary,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: theme.palette.divider,
                    },
                    '& .MuiInputLabel-root.Mui-focused': {
                      color: theme.palette.divider,
                    },
                  }}
                />
              </Box>
              <Button 
                type="submit" 
                variant="contained"
                fullWidth
                sx={{ textDecoration: 'none', color: theme.palette.divider }}>
                  {content.submitButtonText || 'Submit'}
              </Button>
            </form>
          </Paper>
        </Container>
      </Box>
    </Container>
  );
};

export default BookHolidaysForm;
