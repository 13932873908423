import React, { useState, useEffect } from 'react';
import ConsultationForm from '../components/ConsultationForm';
import { Container, Typography, Box, useTheme, Alert } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';
import LoadingSpinner from "../components/LoadingSpinner";

const BookNow = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [screen, setScreen] = useState('mailForm');
  const [mailResponse, setMailResponse] = useState('');
  const [disabledDates, setDisabledDates] = useState([]);
  const [customerIP, setCustomerIP] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/book_now/${language}.json`);
      setContent(response);
    };
    const loadDisabledDates = async () => {
      const response = await import('../data/book_now/rachinee_holidays.json');
      console.log("response: ",response)
      const dates = response.default.map((date) => {
        const [year, month, day] = date.split('-');
        return new Date(year, month - 1, day);
      });
      setDisabledDates(dates);
    };

    const fetchCustomerIP = async () => {
      try {
        const ipResponse = await fetch('https://api64.ipify.org?format=json');
        const ipData = await ipResponse.json();
        setCustomerIP(ipData.ip);
      } catch (error) {
        console.error('Failed to fetch IP or geolocation:', error);
      }
    };

    loadContent();
    loadDisabledDates();
    fetchCustomerIP();
  }, [language]);

  if (!content || !disabledDates) return <LoadingSpinner />;

  const renderConfirmationScreen = () => (
    <Box sx={{ textAlign: 'center', my: 4 }}>
      <Alert severity="success">
        <Typography variant="h6" component="h3" gutterBottom>
          {content.successfully_sent}
        </Typography>
      </Alert>
    </Box>
  );

  const renderFailureScreen = () => (
    <Box sx={{ textAlign: 'center', my: 4 }}>
      <Alert severity="error">
        <Typography variant="h6" component="h3" gutterBottom>
          {content.failed_sent}
        </Typography>
      </Alert>
    </Box>
  );

  if (screen === 'noMailForm' && mailResponse === content.successfully_sent) {
    return renderConfirmationScreen();
  }

  if (screen === 'noMailForm' && mailResponse === content.failed_sent) {
    return renderFailureScreen();
  }

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>

        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>
        
        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
            }}>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.section1}
          </Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.section2}
          </Typography>
          
          <ConsultationForm 
            content={content} 
            setMailResponse={setMailResponse} 
            setScreen={setScreen} 
            disabledDates={disabledDates}
            customerIP={customerIP}/>
        </Box>
      </Box>
    </Container>
  );
};

export default BookNow;
