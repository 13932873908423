import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography, Paper, Select, MenuItem, FormControl, InputLabel, useTheme } from '@mui/material';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { addDays } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import LoadingSpinner from "../components/LoadingSpinner";

const ConsultationForm = ({ content, setMailResponse, setScreen, disabledDates, customerIP }) => {
  const theme = useTheme();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [date, setDate] = useState('');
  const [info, setInfo] = useState('');
  const [category, setCategory] = useState('');
  const categories = content.categories;

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = { name, address, city, phone, email, date, info, category, customerIP };


    try {
      const response = await axios.post('SendConsultationEmail.php', formData);
      console.log(response.data);
      setName('');
      setAddress('');
      setCity('');
      setPhone('');
      setEmail('');
      setDate('');
      setInfo('');
      setCategory('');
      setMailResponse(content.successfully_sent);
    } catch (error) {
      console.error('Error sending email:', error);
      setMailResponse(content.failed_sent);
    }
    setScreen('noMailForm');
  };

  if (!content) return <LoadingSpinner />;

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ p: 4, mt: 4, backgroundColor: theme.palette.background.paper, color: theme.palette.text.primary }}>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mb: 2 }}>
            <FormControl fullWidth variant="outlined" required>
              <InputLabel
                sx={{
                  color: theme.palette.text.secondary,
                  '&.Mui-focused': { color: theme.palette.divider },
                }}
              >
                {content.category_label}
              </InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                label={content.category_label}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.divider,
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.divider,
                  },
                  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.divider,
                  },
                  '& .MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: theme.palette.divider,
                  },
                }}
              >
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>


          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label={content.name}
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              sx={{
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.divider,
                },
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                  transition: 'background-color 5000s ease-in-out 0s',
                },
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label={content.address}
              variant="outlined"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.divider,
                },
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                  transition: 'background-color 5000s ease-in-out 0s',
                },
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label={content.city}
              variant="outlined"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.divider,
                },
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                  transition: 'background-color 5000s ease-in-out 0s',
                },
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label={content.phone}
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
              sx={{
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.divider,
                },
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                  transition: 'background-color 5000s ease-in-out 0s',
                },
              }}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label={content.email}
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              sx={{
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.divider,
                },
                '& input:-webkit-autofill': {
                  WebkitBoxShadow: `0 0 0 100px ${theme.palette.background.paper} inset`,
                  WebkitTextFillColor: theme.palette.text.primary,
                  transition: 'background-color 5000s ease-in-out 0s',
                },
              }}
            />
          </Box>
          <Box sx={{ my: 2 }}>
            <Typography>{content.date}</Typography>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              minDate={new Date()}
              maxDate={addDays(new Date(), 365)}
              filterDate={isWeekday}
              placeholderText="Select a date"
              excludeDates={disabledDates}
              inline
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              fullWidth
              label={content.info}
              variant="outlined"
              multiline
              rows={4}
              value={info}
              onChange={(e) => setInfo(e.target.value)}
              sx={{
                '& .MuiInputLabel-root': {
                  color: theme.palette.text.secondary,
                },
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: theme.palette.divider,
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: theme.palette.divider,
                },
              }}
            />
          </Box>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2, backgroundColor: theme.palette.background.default, color: theme.palette.divider }}
          >
            {content.submit}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

export default ConsultationForm;
