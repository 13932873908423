import { useEffect } from 'react';

const SetHeaderData = ({ content }) => {
  useEffect(() => {
    if (content) {
      // Set the document title
      document.title = content.header_title || 'Rachinee Body and Hair Spa';

      // Set or create the keywords meta tag
      const keywords = content.header_keywords || 'Massage Weesen, Thai Massage, Wellness Schweiz';
      let metaTag = document.querySelector('meta[name="keywords"]');
      if (!metaTag) {
        metaTag = document.createElement('meta');
        metaTag.name = 'keywords';
        document.head.appendChild(metaTag);
      }
      // Update the content of the meta tag
      metaTag.content = keywords;
    }
  }, [content]); // Re-run when content changes

  return null; // This component doesn't render anything
};

export default SetHeaderData;
