import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, Drawer, List, ListItem, ListItemText, Typography, useTheme, useMediaQuery } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, useNavigate } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import Flag from 'react-world-flags';
import Languages from './Languages';
import LoadingSpinner from "../components/LoadingSpinner";

const Header = ({ setLanguage, language}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [spaMenuAnchorEl, setSpaMenuAnchorEl] = useState(null);
  const [hairMenuAnchorEl, setHairMenuAnchorEl] = useState(null);
  const [headerContent, setHeaderContent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadHeaderContent = async () => {
      const response = await import(`../data/header/${language}.json`);
      setHeaderContent(response);
    };
    loadHeaderContent();
  }, [language]);

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (code) => {
    setLanguage(code);
    handleMenuClose();
  };

  const handleSpaMenuOpen = (event) => {
    setSpaMenuAnchorEl(event.currentTarget);
  };

  const handleSpaMenuClose = () => {
    setSpaMenuAnchorEl(null);
  };

  const handleHairMenuOpen = (event) => {
    setHairMenuAnchorEl(event.currentTarget);
  };

  const handleHairMenuClose = () => {
    setHairMenuAnchorEl(null);
  };

  const handleRequestPage = (service) => {
    setSpaMenuAnchorEl(null);
    setHairMenuAnchorEl(null);
    navigate('/' + service);
  };

  if (!headerContent) return <LoadingSpinner />;

  const renderDesktopMenu = () => (
    <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
      <Button color="inherit" component={Link} to="/">
        {headerContent.home}
      </Button>
      <Button color="inherit" onClick={handleSpaMenuOpen}>
        {headerContent.massage.title}
      </Button>
      <Menu
        anchorEl={spaMenuAnchorEl}
        open={Boolean(spaMenuAnchorEl)}
        onClose={handleSpaMenuClose}
        >
        {Object.keys(headerContent.massage.submenus).map((service) => (
          <MenuItem key={service} onClick={() => handleRequestPage(service)}>
            {headerContent.massage.submenus[service]}
          </MenuItem>
        ))}
      </Menu>

      <Button color="inherit" onClick={handleHairMenuOpen}>
        {headerContent.hair_studio.title}
      </Button>
      
      <Menu
        anchorEl={hairMenuAnchorEl}
        open={Boolean(hairMenuAnchorEl)}
        onClose={handleHairMenuClose}
        >
        {Object.keys(headerContent.hair_studio.submenus).map((service) => (
          <MenuItem key={service} onClick={() => handleRequestPage(service)}>
            {headerContent.hair_studio.submenus[service]}
          </MenuItem>
        ))}
      </Menu>

      <Button color="inherit" component={Link} to="/shop">
        {headerContent.shop}
      </Button>

      <Button color="inherit" component={Link} to="/location">
        {headerContent.location}
      </Button>
      
      <Button color="inherit" component={Link} to="/book_now">
        {headerContent.booking}
      </Button>

      <Button color="secondary" component={Link} to="/virtual_assistant">
        {headerContent.virtual_assistant}
      </Button>

      <IconButton
        edge="end"
        color="inherit"
        onClick={handleMenuOpen}
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {Languages.map((language) => (
          <MenuItem key={language.code} onClick={() => handleLanguageChange(language.code)}>
            <Flag code={language.flag} height="16" style={{ marginRight: '8px' }} />
            {language.name}
          </MenuItem>
        ))}
      </Menu>
      <IconButton color="inherit" href="https://www.instagram.com/rachinee_body_and_hair_spa?utm_source=qr&igsh=MWpmMHYwanU5ZXZybA==" target="_blank" sx={{ mx: 1 }}>
        <InstagramIcon />
      </IconButton>
    </Box>
  );

  const renderMobileMenu = () => (
    <>
      <IconButton edge="start" color="inherit" onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem button component={Link} to="/" onClick={() => setDrawerOpen(false)}>
            <ListItemText primary={headerContent.home} />
          </ListItem>
          {Object.keys(headerContent.massage.submenus).map((service) => (
            <ListItem
              button
              key={service}
              onClick={() => {
                handleRequestPage(service);
                setDrawerOpen(false);
              }}
            >
              <ListItemText primary={headerContent.massage.submenus[service]} />
            </ListItem>
          ))}
          {Object.keys(headerContent.hair_studio.submenus).map((service) => (
            <ListItem
              button
              key={service}
              onClick={() => {
                handleRequestPage(service);
                setDrawerOpen(false);
              }}
            >
              <ListItemText primary={headerContent.hair_studio.submenus[service]} />
            </ListItem>
          ))}
          <ListItem button component={Link} to="/shop" onClick={() => setDrawerOpen(false)}>
            <ListItemText primary={headerContent.shop} />
          </ListItem>
          <ListItem button component={Link} to="/location" onClick={() => setDrawerOpen(false)}>
            <ListItemText primary={headerContent.location} />
          </ListItem>
          <ListItem button component={Link} to="/book_now" onClick={() => setDrawerOpen(false)}>
            <ListItemText primary={headerContent.booking} />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/virtual_assistant"
            onClick={() => setDrawerOpen(false)}
            sx={{
              backgroundColor: 'secondary.main',
              color: 'black',
              '&:hover': { backgroundColor: 'secondary.dark' },
            }}
          >
            <ListItemText primary={headerContent.virtual_assistant} />
          </ListItem>
          {Languages.map((language) => (
            <MenuItem
              key={language.code}
              onClick={() => {
                handleLanguageChange(language.code);
                setDrawerOpen(false);
              }}
            >
              <Flag code={language.flag} height="16" style={{ marginRight: '8px' }} />
              {language.name}
            </MenuItem>
          ))}
          <ListItem button component="a" href="https://www.instagram.com/rachinee_body_and_hair_spa" target="_blank" onClick={() => setDrawerOpen(false)}>
            <InstagramIcon />
            <ListItemText primary="Instagram" />
          </ListItem>
        </List>
      </Drawer>
    </>
  );  

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.background.banner }}>
      {isMobile && (
        <Toolbar>
          {renderMobileMenu()}
          <img src="/images/Logo.png" alt="Logo" style={{ minWidth: '100px', maxWidth: '300px',  marginTop: '20px', marginBottom: '20px'}} />
        </Toolbar>
      )}
      {!isMobile && (
        <Toolbar sx={{ flexDirection: 'column', alignItems: 'center' }}>
          <img src="/images/Logo.png" alt="Logo" style={{ minWidth: '100px', maxWidth: '400px', marginTop: '20px', marginBottom: '20px' }} />
          {renderDesktopMenu()}
        </Toolbar>
      )}
    </AppBar>
  );
};

export default Header;
