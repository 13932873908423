import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, TextField, Button, useTheme, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SetHeaderData from '../components/SetHeaderData';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from "../components/LoadingSpinner";

const Checkout = ({ language }) => {
  const theme = useTheme();
  const location = useLocation();
  const { shoppingCart } = location.state || {};
  const [content, setContent] = useState(null);
  const [amount] = useState(
    shoppingCart.reduce((total, article) => total + article.price * article.quantity, 0).toFixed(2)
  );
  const [view, setView] = useState('checkout');
  const SUMUP_MERCHANT_CODE = process.env.REACT_APP_SUMUP_MERCHANT_CODE
  const SUMUP_CLIENT_ID = process.env.REACT_APP_SUMUP_CLIENT_ID;
  const SUMUP_CLIENT_SECRET = process.env.REACT_APP_SUMUP_CLIENT_SECRET;
  const SUMUP_CODE = process.env.REACT_APP_SUMUP_CODE;
  const [loading, setLoading] = useState(null);

  const [customer, setCustomer] = useState({
    name: '',
    middleName: '',
    familyName: '',
    address: '',
    zip: '',
    city: '',
    country: '',
    phoneNumber: '',
    eMail: '',
  });

  const [card, setCard] = useState({
    name: '',
    number: '',
    expDateYear: '',
    expDateMonth: '',
    cvv: '',
    type: ''
  })

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/shop/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  useEffect(() => {
    // Validate required fields
    const requiredFields = ['name', 'familyName', 'address', 'zip', 'city', 'country', 'eMail'];
    const isValid = requiredFields.every((field) => customer[field]?.trim() !== '');
    setIsFormValid(isValid);
  }, [customer]);

  useEffect(() => {
    // Validate required fields
    const requiredFields = ['name', 'number', 'expDateMonth', 'expDateYear', 'cvv'];
    const isValid = requiredFields.every((field) => card[field]?.trim() !== '');
    setIsFormValid(isValid);
  }, [card]);

  const handleCustomerChange = (field, value) => {
    setCustomer((prevCustomer) => ({
      ...prevCustomer,
      [field]: value,
    }));
  };

  const handleCardChange = (field, value) => {
    setCard((prev) => ({ ...prev, [field]: value }));

    if (field === 'number') {
      const cardType = determineCardType(value);
      setCard((prev) => ({ ...prev, type: cardType }));
    }
  };

  const determineCardType = (number) => {
    if (/^4/.test(number)) return 'VISA';
    if (/^5[1-5]/.test(number)) return 'MASTERCARD';
    if (/^3[47]/.test(number)) return 'AMEX';
    if (/^6(?:011|5)/.test(number)) return 'DISCOVER';
    if (/^3(?:0[0-5]|[68])/.test(number)) return 'DINERS';
    if (/^35/.test(number)) return 'JCB';
    if (/^5[0678]|6/.test(number)) return 'MAESTRO';
    return '';
  };

  const handleSubmit = async (amount, customer) => {

    if (!isFormValid) return;
    
    setLoading(true)
    
    const checkout_reference = uuidv4();

    const purchase_status_params =
      `?checkout_reference=${checkout_reference}` +
      `&name=${customer.name}` +
      `&familyName=${customer.familyName}` +
      `&address=${customer.address}` +
      `&zip=${customer.zip}` +
      `&city=${customer.city}` +
      `&country=${customer.country}` +
      `&phoneNumber=${customer.phoneNumber}` +
      `&eMail=${customer.eMail}` +
      `&amount=${amount}`;

    const return_params =
      `?name=${customer.name}` +
      `&familyName=${customer.familyName}` +
      `&lang=${language}` +
      `&checkout_reference=${checkout_reference}`;

    console.log('return_params:', return_params);
    console.log('purchase_status_params:', purchase_status_params);

    const purchase_description = `To track your order, please note: ${checkout_reference}`;

    try {
      const trackOrderResponse = await fetch('https://rachinee.ch/TrackOrders.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          checkout_reference,
          customer,
          articles: shoppingCart,
          amount,
        }),
      });

      if (!trackOrderResponse.ok) {
        const trackOrderResult = await trackOrderResponse.json();
        console.error('Failed to save order:', trackOrderResult);
        alert('Failed to save your order. Please try again.');
        setLoading(false)
        return;
      }

      console.log('Order saved successfully');

      const tokenResponse = await fetch('https://api.sumup.com/token', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          client_id: SUMUP_CLIENT_ID,
          client_secret: SUMUP_CLIENT_SECRET,
          code: SUMUP_CODE,
        }),
      });

      if (!tokenResponse.ok) {
        throw new Error('Failed to fetch SumUp token');
      }

      const { access_token } = await tokenResponse.json();

      console.log('Access token received:', access_token);

      const createCheckoutResponse = await fetch('https://api.sumup.com/v0.1/checkouts', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({
          amount,
          currency: 'CHF',
          checkout_reference,
          description: purchase_description,
          merchant_code: SUMUP_MERCHANT_CODE,
          return_url: `https://rachinee.ch/CheckoutStatus.php${purchase_status_params}`,
          redirect_url: `https://rachinee.ch/complete_purchase${return_params}`
        }),
      });

      if (!createCheckoutResponse.ok) {
        throw new Error('Failed to create checkout');
      }

      const createCheckoutData = await createCheckoutResponse.json();

      const closeCheckoutResponse = await fetch('https://api.sumup.com/v0.1/checkouts/' + createCheckoutData.id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`
        },
        body: JSON.stringify({
            "payment_type": "card",
            "card": {
              "name": card.name,
              "number": card.number,
              "last_4_digits": card.number[-4],
              "expiry_month": card.expDateMonth,
              "expiry_year": card.expDateYear,
              "cvv": card.cvv,
              "type": card.type
            }
        }),
      });
      
      const closeCheckoutData = await closeCheckoutResponse.json();

      if ((!closeCheckoutResponse.ok) || (!closeCheckoutData.status === 'PAID')) {
        throw new Error('Failed to close checkout');
      } else {
        // the validation code needs to be inserted here
        window.location.href = closeCheckoutData.next_step.url
      }
    } catch (error) {
      console.error('Payment error:', error);
      alert('An error occurred during the payment process. Please try again.');
      setLoading(false)
    }
  };

  if (!content) return <LoadingSpinner />;

  if (!shoppingCart || !shoppingCart.length) {
    return <Typography>{content.no_items}</Typography>;
  }

  return (
    <Container>
      <SetHeaderData content={{ title: 'Checkout' }} />

      {loading && (
        <LoadingSpinner />
      )}

      { view === 'checkout' ?
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>
            {content.checkout_title}
          </Typography>
          <Box
            sx={{
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h5" component="h1" gutterBottom sx={{ my: 2 }}>
              {content.shopping_cart}
            </Typography>
            <Box>
              {shoppingCart.map((item, index) => (
                <Typography key={index} variant="body1" sx={{ my: 1 }}>
                  {item.quantity} x {item.title}: {item.price} CHF
                </Typography>
              ))}
            </Box>
            <Typography variant="h6" sx={{ my: 2 }}>
              {content.total}: {amount} CHF
            </Typography>
          </Box>

          <Box
            sx={{
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h5" component="h1" gutterBottom sx={{ my: 2 }}>
              {content.customer_address_title}
            </Typography>
            <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>
              {content.customer_address_text}
            </Typography>
            
            <Box sx={{ my: 4 }}>
              <TextField
                label={content.address.name}
                variant="outlined"
                fullWidth
                required
                value={customer.name}
                onChange={(e) => handleCustomerChange('name', e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label={content.address.middle_name}
                variant="outlined"
                fullWidth
                value={customer.middleName}
                onChange={(e) => handleCustomerChange('middleName', e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label={content.address.family_name}
                variant="outlined"
                fullWidth
                required
                value={customer.familyName}
                onChange={(e) => handleCustomerChange('familyName', e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label={content.address.address}
                variant="outlined"
                fullWidth
                required
                value={customer.address}
                onChange={(e) => handleCustomerChange('address', e.target.value)}
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', mb: 2 }}>
                <TextField
                  label={content.address.zip}
                  variant="outlined"
                  required
                  value={customer.zip}
                  onChange={(e) => handleCustomerChange('zip', e.target.value)}
                  sx={{ width: 150 }}
                />
                <TextField
                  label={content.address.city}
                  variant="outlined"
                  required
                  value={customer.city}
                  onChange={(e) => handleCustomerChange('city', e.target.value)}
                  sx={{ flex: 1 }}
                />
              </Box>
              <FormControl fullWidth sx={{ flex: 1 }}>
                <InputLabel>{content.address.country}</InputLabel>
                <Select
                  value={customer.country}
                  onChange={(e) => handleCustomerChange('country', e.target.value)}
                  label="Country"
                >
                  <MenuItem value="Switzerland">Switzerland</MenuItem>
                  <MenuItem value="Liechtenstein">Liechtenstein</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label={content.address.phone}
                variant="outlined"
                fullWidth
                value={customer.phoneNumber}
                onChange={(e) => handleCustomerChange('phoneNumber', e.target.value)}
                sx={{ mb: 2 }}
              />
              <TextField
                label={content.address.email}
                variant="outlined"
                fullWidth
                required
                value={customer.eMail}
                onChange={(e) => handleCustomerChange('eMail', e.target.value)}
                sx={{ mb: 2 }}
              />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', my: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setView('close_checkout')
                  setIsFormValid(false)
                }}
                disabled={!isFormValid}
              >
                {content.goto_payment}
              </Button>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
              <Typography variant="h6" component="h1" gutterBottom >
                {content.payment_method}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
              <img src="images/payment_methods/mastercard.png" alt="Mastercard" style={{ minWidth: 80, maxWidth: 150 }} />
              <img src="images/payment_methods/visa.png" alt="VISA" style={{ minWidth: 80, maxWidth: 150 }} />
              <img src="images/payment_methods/maestro.png" alt="Maestro" style={{ minWidth: 80, maxWidth: 150 }} />
              <img src="images/payment_methods/amex.png" alt="American Express" style={{ minWidth: 80, maxWidth: 150}} />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
              <img src="images/payment_methods/jcb.png" alt="JCB" style={{ minWidth: 80, maxWidth: 150}} />
              <img src="images/payment_methods/diners-club-international.png" alt="Diners" style={{ minWidth: 80, maxWidth: 150}} />
              <img src="images/payment_methods/discover.png" alt="Discover" style={{ minWidth: 80, maxWidth: 150}} />
            </Box>
          </Box>
        </Box>
        :
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>
            {content.checkout_title}
          </Typography>

          <Box
            sx={{
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}>

            <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>
              {content.payment_instructions}
            </Typography>
            
            <Box sx={{ my: 4 }}>
              <TextField
                label={content.card.name}
                variant="outlined"
                fullWidth
                required
                value={card.name}
                onChange={(e) => handleCardChange('name', e.target.value)}
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  label={content.card.number}
                  variant="outlined"
                  fullWidth
                  required
                  value={card.number}
                  onChange={(e) => handleCardChange('number', e.target.value)}
                  sx={{ mb: 2 }}
                />
                {card.number &&
                  <FormControl sx={{ mb: 2,  backgroundColor: theme.palette.divider, borderRadius: 2, width: 300 }}>
                    <InputLabel>{content.type}</InputLabel>
                    <Select
                      value={card.type}
                      onChange={(e) => handleCardChange('type', e.target.value)}
                      disabled
                      >
                      <MenuItem value="VISA">VISA</MenuItem>
                      <MenuItem value="MASTERCARD">MasterCard</MenuItem>
                      <MenuItem value="AMEX">American Express</MenuItem>
                      <MenuItem value="DISCOVER">Discover</MenuItem>
                      <MenuItem value="DINERS">Diners Club</MenuItem>
                      <MenuItem value="JCB">JCB</MenuItem>
                      <MenuItem value="MAESTRO">Maestro</MenuItem>
                    </Select>
                  </FormControl>
                }
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                <TextField
                  label={content.card.exp_month}
                  variant="outlined"
                  value={card.expDateMonth}
                  required
                  onChange={(e) => handleCardChange('expDateMonth', e.target.value)}
                  sx={{  width: 200 }}
                />
                <TextField
                  label={content.card.exp_year}
                  variant="outlined"
                  value={card.expDateYear}
                  required
                  onChange={(e) => handleCardChange('expDateYear', e.target.value)}
                  sx={{  width: 200 }}
                />
                <TextField
                  label={content.card.cvv}
                  variant="outlined"
                  required
                  value={card.cvv}
                  onChange={(e) => handleCardChange('cvv', e.target.value)}
                  sx={{ mb: 2 }}
                />
              </Box>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', my: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleSubmit(amount, customer)}
                disabled={!isFormValid}
              >
                {content.confirm_order}
              </Button>
            </Box>
          </Box>
        </Box>
      }
    </Container>
  );
};

export default Checkout;
