import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';
import LoadingSpinner from "../components/LoadingSpinner";

const FaceTreatment = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [priceContent, setPriceContent] = useState(null);
  const [prices, setPrices] = useState(null);
  const [rowsFacialsSmooth, setRowsFacialsSmooth] = useState(null);
  const [rowsFacialsSlowRenewal, setRowsFacialsSlowRenewal] = useState(null);
  const [rowsFacialsTraditionalThai, setRowsFacialsTraditionalThai] = useState(null);
  

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/face_treatment/${language}.json`);
      const priceContentResponse = await import(`../data/massage_prices/${language}.json`);
      const pricesResponse = await import(`../data/massage_prices/prices.json`);
      setPriceContent(priceContentResponse);
      setContent(response);
      setPrices(pricesResponse);
    };
    loadContent();
  }, [language]);

  useEffect(() => {
    if (prices) {
      const loadRows = () => {

        setRowsFacialsSmooth([
          { duration: prices.facials.smooth.short.duration, price: prices.facials.smooth.short.price, discount:  prices.facials.smooth.short.price * 0.8}
        ]);

        setRowsFacialsSlowRenewal([
          { duration: prices.facials.slow_renewal.short.duration, price: prices.facials.slow_renewal.short.price, discount: prices.facials.slow_renewal.short.price * 0.8 }
        ]);

        setRowsFacialsTraditionalThai([
          { duration: prices.facials.traditional_thai.short.duration, price: prices.facials.traditional_thai.short.price, discount: prices.facials.traditional_thai.short.price * 0.8 }
        ]);

      };
      loadRows();
    }
  }, [prices]);

  if (!content || 
    !rowsFacialsSmooth ||
    !rowsFacialsSlowRenewal ||
    !rowsFacialsTraditionalThai
  ) return <LoadingSpinner />;

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>{content.title}</Typography>

        <Box
          component="img"
          sx={{
            width: '100%',
            maxWidth: 1254,
            margin: '0 auto',
            display: 'block',
            borderRadius: '10px'
          }}
          alt="Facial Massage"
          src="/images/out/facial_massage.jpg"
        />

        <Box
          sx={{
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Typography variant="h5" component="h2" gutterBottom sx={{ }}>{content.facials.smooth.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>{content.facials.smooth.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 350 }}>
              <Table sx={{ minWidth: 150 }} aria-label="smooth price table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFacialsSmooth.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Typography variant="h5" component="h2" gutterBottom sx={{ }}>{content.facials.slow_renewal.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{  my: 2 }}>{content.facials.slow_renewal.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 350 }}>
              <Table sx={{ minWidth: 150 }} aria-label="slow renewal price table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFacialsSlowRenewal.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ }}>{content.facials.traditional_thai.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ my: 2 }}>{content.facials.traditional_thai.description}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ mb: 4, maxWidth: 350 }}>
              <Table sx={{ minWidth: 150 }} aria-label="traditional thai price table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>{priceContent.duration}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.price}</TableCell>
                    <TableCell align="right" sx={{ fontWeight: 'bold' }}>{priceContent.discount}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowsFacialsTraditionalThai.map((row) => (
                    <TableRow key={row.duration}>
                      <TableCell component="th" scope="row">{row.duration} {priceContent.duration_metric}:</TableCell>
                      <TableCell align="right">{row.price}.-</TableCell>
                      <TableCell align="right">{row.discount}.-</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>

      </Box>
    </Container>
  );
};

export default FaceTreatment;