import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';
import LoadingSpinner from "../components/LoadingSpinner";

const About = ({language}) => {
  const [content, setContent] = useState(null);
  const theme = useTheme();

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/about/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <LoadingSpinner />;

  return (
    <Container>

      <SetHeaderData content={content} />

      <Box sx={{ my: 4 }}>

        <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>{content.title}</Typography>

        <Box
          sx={{
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
        {content.paragraphs.map((paragraph) => (
          <Typography variant="body1" component="p" sx={{ my: 2 }}>{paragraph}</Typography>
        ))}
        </Box>
      </Box>
    </Container>
  );
};

export default About;
