import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';
import SetHeaderData from '../components/SetHeaderData';
import LoadingSpinner from "../components/LoadingSpinner";

const CompletePurchase = ({ setLanguage }) => {
  const name = new URLSearchParams(location.search).get('name') || 'Name';
  const familyName = new URLSearchParams(location.search).get('familyName') || 'FamilyName';
  const language = new URLSearchParams(location.search).get('lang') || 'de';
  const checkout_reference = new URLSearchParams(location.search).get('checkout_reference') || 'undef';
  const theme = useTheme();
  const [showSuccess, setShowSuccess] = useState(false);
  const SUMUP_CLIENT_ID = process.env.REACT_APP_SUMUP_CLIENT_ID;
  const SUMUP_CLIENT_SECRET = process.env.REACT_APP_SUMUP_CLIENT_SECRET;
  const SUMUP_CODE = process.env.REACT_APP_SUMUP_CODE;
  const [content, setContent] = useState(null);

  setLanguage(language);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/shop/${language}.json`);
      setContent(response);
    };

    const fetchTokenAndCheckStatus = async () => {
      try {
        // Fetch the SumUp token
        const tokenResponse = await fetch('https://api.sumup.com/token', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            client_id: SUMUP_CLIENT_ID,
            client_secret: SUMUP_CLIENT_SECRET,
            code: SUMUP_CODE,
          }),
        });

        if (!tokenResponse.ok) {
          throw new Error('Failed to fetch SumUp token');
        }

        const { access_token } = await tokenResponse.json();
        console.log('Access token received:', access_token);

        // Fetch the checkout status
        const checkoutResponse = await fetch(
          `https://api.sumup.com/v0.1/checkouts?checkout_reference=${checkout_reference}`,
          {
            method: 'GET',
            headers: { Authorization: `Bearer ${access_token}` },
          }
        );

        if (!checkoutResponse.ok) {
          throw new Error('Failed to fetch checkout details');
        }

        const checkouts = await checkoutResponse.json();

        // Check if any transaction has status 'PAID'
        const isPaid = checkouts.some((checkout) => checkout.status === 'PAID');
        setShowSuccess(isPaid);

        if (isPaid) {
          console.log('Checkout is successfully paid.');
        } else {
          console.warn('No successful payment found.');
        }
      } catch (error) {
        console.error('Error during SumUp API interaction:', error);
      }
    };

    loadContent();
    fetchTokenAndCheckStatus();
  }, [language, checkout_reference, SUMUP_CLIENT_ID, SUMUP_CLIENT_SECRET, SUMUP_CODE]);

  if (!content) return <LoadingSpinner />;

  return (
    <Container>
      <SetHeaderData content={{ title: 'Complete Purchase' }} />
      <Box sx={{ my: 4 }}>
        {showSuccess && (
          <Box
            sx={{
              textAlign: 'center',
              my: 4,
              p: 2,
              border: '1px solid',
              borderColor: theme.palette.divider,
              borderRadius: 2,
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <Typography variant="h4" component="h1" gutterBottom sx={{ my: 2 }}>
              {content.complete_purchase.title} {name} {familyName}
            </Typography>
            <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>
              {content.complete_purchase.message}
            </Typography>
            <Typography variant="body" component="p" gutterBottom sx={{ my: 2 }}>
              {content.complete_purchase.checkout_reference}: {checkout_reference}
            </Typography>
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default CompletePurchase;
